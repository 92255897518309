import {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {CreateHabit} from "../components/CreateHabit";

export const RoutineView = () => {
    
    const [routine, setRoutine] = useState<any>();

    useEffect(() => {

        const fetch = async () => {
            const client = new ApiClient();

            const data = await client.get('routine')
            
            setRoutine(data)
        }
        
        fetch();
        
    }, []);
    
    if(!routine) return <Loading/>

    const habitCreated = async (habit: any) => {
        routine.habits.push(habit);
        
        setRoutine({...routine})

        const client = new ApiClient();
        
        await client.put('routine', routine)
    };

    return <>
        <p>{routine.name}</p>
        
        <CreateHabit habitCreated={habitCreated}/>
    </>
}