import React, {useState} from "react";
import {Button, Card, Form} from "react-bootstrap";

type Props = {
    habitCreated: (habit: any) => void;
}

export const CreateHabit = (props: Props) => {

    const [habit, setHabit] = useState<any>({})
    
    const onSubmitHandler = () => {
        props.habitCreated(habit)
    }
    
    const handleChange = (e: any) => {
        setHabit({
            ...habit,
            [e.target.name]: e.target.value
        });
    }
    
    return <>
        <Card>
            <Card.Header>
                <Card.Title>Create Habit</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group controlId='formHabit'>
                        <Form.Label>Routine Name</Form.Label>
                        <Form.Control
                            name="name"
                            type='text'
                            value={habit.name}
                            placeholder='Enter Habit Name'
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button variant='primary' type='button' onClick={onSubmitHandler}>Create</Button>
                </Form>
            </Card.Body>
        </Card>
    </>
}